.logoNavbar {
  background-color: rgba(238, 238, 238, 0.835);
}

#link-header {
  margin-left: 20px;
  margin-right: 20px;
  color:white;
  font-size: 18px;
  font-family: 'Quicksand-Medium', sans-serif;
}

#link-header:hover {
  color: #23c4f4;
}


.navbar-toggler {
  margin-left: 20px;
}




.header {
  display: flex;
  flex-direction: column;
  align-items: center; 
  text-align: center; 
  min-height: 100vh; 
}

/* banner */

/* .logos {
  max-width: 1000px;
} */


.banner {
  position: relative;
  /* overflow: hidden; */
  height: 100vh;
}

#banner-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}


/* @media only screen and (max-width: 390px) {
  .banner {
    
    height: 1vh;
    background-position: center; 
}
} */






.logo-container {
  max-width: 800px; 
 
  margin-right: auto;
 
 margin-left: auto;
 height: auto; 

}

.logo {
  max-width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.navbar {
  background-color: #343a40;
  color: #fff;
}


.social-media {
  display: flex;
  height: 25vh;
  justify-content: center; 
  align-items: center;  
}


.socialmedia {
  width: 50px; 
  height: auto; 
  margin-bottom: 10px; 
}


#insta-img {
  transition: transform 0.3s ease; 
}

#face-img {
  transition: transform 0.3s ease; 
}


#insta-img:hover {
  transform: scale(1.1); 
}

#face-img:hover {
  transform: scale(1.1); 
}



.containerBtn {
  display: flex;
  justify-content: center; 
  align-items: center; 
  height: auto; 
  padding-bottom: 300px;
}

.rounded-button {
  padding: 10px 20px; 
  border-radius: 30px; 
  background-color: white; 
  color: #23c4f4; 
  border: none; 
  cursor: pointer; 
  font-size: 29px; 
}

@media 
       (max-width: 667px) and (max-height: 375px) {
  .logos {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media 
       (max-width: 896px) {
  .logos {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media 
       (max-width: 932px) {
  .logos {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* @media (max-width: 375px) and (max-height: 667px),
       (max-width: 667px) and (max-height: 375px) {
  .rounded-button {
    font-size: 17px;

  }
} */


@media only screen and (max-width: 450px) {
  .banner {
    
    height: 20vh;
    background-position: center; 
}
}

@media (max-width: 667px) and (max-height: 375px){
  .banner {
    height: 85vh;
  }
  }


  /* @media (max-width: 844px){
    .banner {
      height: 110vh;
    }
    } */

@media (max-width: 896px){
  .banner {
    height: 100vh;
  }
  }

  

@media only screen and (max-width: 1005px) {
  .banner {
    height: 80vh;
    background-position: center; 
}
}







.rounded-button:hover {
  background-color: #e93291; 
  color: white;
}


@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');
.rounded-button, .titulo-services {
  font-family: 'Quicksand', bold, sans-serif;
  font-weight: 900;
  text-transform: uppercase;
}



/* SERVICES */

.imagen-con-titulo {
  text-align: center;
}

.imagen-con-titulo img {
  width: 150px;
  height: auto;
  margin: 0 80px; 
}



.imag-container {
  flex-wrap: wrap;
  display: flex; 
  justify-content: center; 
  align-items: flex-start;
  padding-top: 100px;
  padding-bottom: 100px;
}

@media only screen and (max-width: 1005px) {
  .imag-container {
    padding-top: 40px;
}
}





.ourServices {
  background-color: #262a34;
}


.titulo-services {
  font-size: 19px;
  margin-top: 10px; 
  max-width: 200px; 
  white-space: normal; 
text-align: center;
margin-left: auto;
    margin-right: auto;
}

@font-face {
  font-family: 'Quicksand'; 
  src: url('../public/font/Quicksand-Bold.ttf') format('truetype');
  font-weight: 700; 
  font-style: normal; 
}

@font-face {
  font-family: 'TittleFontKK'; 
  src: url('../public/font/TittleFontKK.ttf') format('truetype');
  font-weight: 700; 
  font-style: normal; 
}

.titulo-services {
  font-family: 'Quicksand', sans-serif;
  font-weight: 800; 
  color: white;

}

.Our-Services {
  color: white;
  text-align: center;
  padding-top: 8px; 
  font-size: 40px;
  padding-top: 80px;
  font-family: 'Quicksand-Medium-Bold', sans-serif; 
  font-weight: 900;
}

.servicesTxt {
  color: white;
  width: 200px;
  text-align: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

/* Stores */

.link-stores {
  text-decoration: none;
  color: white;
  padding-top: 10px;
}

.Our-Stores- {
  color: rgb(0, 154, 205);
  text-align: center;
  font-size: 35px;
  font-family: 'Quicksand-Medium-Bold', sans-serif;
  font-weight: 900;
  background-color: rgba(255, 255, 255, 0.8);
  padding-top:10px;
  padding-bottom:10px;
}

.stores {
  font-family: 'Quicksand', sans-serif;
  font-weight: medium;
  color: #262a34;
  background: rgba(255, 255, 255, 0.7); /* 50% transparent white */
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
  padding: 15px;
  /* padding-left: 15px; */
  text-align: center;
  border-radius: 15px; 
}


.titulo-stores {
  padding-top: 8px;
}


/* .img-container {
  flex-wrap: wrap;
  display: flex; 
  justify-content: center; 
  align-items: flex-start;
  margin-top: 30px;
  padding-bottom: 100px;
} */
.img-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Tres columnas */
  grid-gap: 20px; /* Espaciado entre los elementos */
  justify-content: center; 
  align-items: start;
  margin: 30px auto 100px; /* Margen para centrar y ajustar espacios */
  max-width: 1000px; /* Limitar el ancho del contenedor */
}


.titulo-stores {
  font-size: 23px;
  max-width: 290px; 
  white-space: normal; 
text-align: center;
margin-left: auto;
    margin-right: auto;
    font-family: 'Quicksand', sans-serif; 
    font-weight: 800; 
    color: white;
}

.img-con-titulo img {
  width: 290px;
  height: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;

  /* margin-bottom: 15px; */
}

.our-Stores {
  background-image: url('../public/img/background-stores.jpg');
  background-size: cover; 
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh; /* Asegura al menos una altura mínima del 100% de la ventana */
  height: auto; /* Permite que la altura sea dinámica basada en el contenido */
  padding: 20px 0; /* Añade un espacio superior e inferior */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}



@media only screen and (max-width: 1005px) {
  .our-Stores {
    height: 100vh;
}
}

@media only screen and (max-width: 896px) {
  .our-Stores {
      height: 230vh;
  }
}

@media only screen and (max-width: 667px) {
  .our-Stores {
      height: 110vh;
  }
}


@media only screen and (max-width: 375px) {
  .our-Stores {
    height: 120vh;
}
}


.transparent {
  opacity: 0;
}




.img-con-titulo {
  margin: 20px;
  background-color: transparent;
  transition: transform 0.3s ease;
}

.img-con-titulo:hover {
  transform: scale(1.1); 
}

@media screen and (max-width: 768px) {
  .Our-Stores {
    font-size: 30px; 
  }

  .titulo-stores {
    font-size: 18px; 
    max-width: 200px; 
  }

  .img-container {
    padding: 10px; 
    /* margin-bottom: 15px; */
  }





  /* nuevo */
  @media screen and (max-width: 768px) {
    .img-container {
      grid-template-columns: repeat(2, 1fr); /* Dos columnas para pantallas medianas */
    }
  }
  
  @media screen and (max-width: 480px) {
    .img-container {
      grid-template-columns: 1fr; /* Una columna para pantallas pequeñas */
    }
  }
/* ?nuevo   */





  .img-con-titulo img {
    max-width: 200px;
  }

  .img-con-titulo {
    margin: 5px;
  }
}




/* COLLAGE */



.collage-title {
  margin-top: 20px; 
  color: #262a34;
  text-align: center;
  padding-top: 8px; 
  font-size: 45px;
  padding-top: 80px;
  font-family: 'TittleFontKK', sans-serif;
}




.collage-section {
  display: flex; 
  align-items: center;
  height: 500px;
  overflow-x: auto;
  white-space: nowrap;
}

.collage-section img {
  height: 100%; 
  max-width: none; 
  margin-right: 5px; 
}


.Prizes {
  height: 700px;
  padding-top: 50px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;
}


.collage-section {
  position: relative;
}

.collage-section .slick-dots {
  position: absolute;
  bottom: 20px; 
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1;
}

.collage-section .slick-dots.slick-active {
  opacity: 1;
}




/* CONTACT */

/* .form-control {
  margin-bottom: 10px;
} */

#message {
  margin-top: 10px;
}

#validationCustom01 {
  margin-bottom: 10px;
}

/* #recaptcha-accessible-status {
  margin-top: 10px;
} */


#contact {
  padding-top: 50px;
  padding-bottom: 50px;
  max-width: 1200;
  min-width: 200px;
}

.contact-title {
  color: #262a34;
  text-align: center;
  padding-top: 20px; 
  font-size: 40px;
  font-family: 'Quicksand-Medium-Bold', sans-serif;
  font-weight: 600;
}

.info-email {
  text-align: left;
}


.contact-txt {
  text-align: center;
  max-width: 1000px;
  margin-left: auto;
  margin-right:auto;
  height: auto;
}

.contact-txt p{
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 50px;
  margin-left: 15px;
  margin-right: 15px;
}

.info-email h4 {
  font-family: 'Quicksand', sans-serif;
  font-weight: 800;
}

.info-email h6 {
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  font-size: 18px;
}

.contact {
  background-image: url('../public/img/background-stores.jpg');
  background-size: cover; 
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* height: 85vh; */
  padding-top: 50px;
  padding-bottom: 40px;
}


/* @media screen and (max-width: 768px) {
  .contact {
    height: 0%; 
  }
} */


/* FOOTER */

.footer {
  height: auto;
  /* position: relative; */
  /* bottom: 0; */
}

.list-unstyled {
  font-family: 'Quicksand-Medium', sans-serif;
  /* font-weight: 600; */
  font-size: 18px;
  margin-bottom: 0px;
  padding-bottom: 30px;
  padding-top: 30px;
  background-color: #343a40;
}

.link-item a:hover {
  color:#e93291;
}





.linkFooter {
  margin-right: 30px;
  margin-left:30px;
  color:white;
}

/* Estilos para pantallas pequeñas */
@media (max-width: 768px) {
  .links-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .link-item {
    margin-bottom: 10px;
  }

  .linkFooter {
    font-size: 15px;
  }
  .list-unstyled {
    font-size: 12px;
    padding-bottom: 10px;
    padding-top: 20px;
}
.text-center {
  font-size: 13px;
}
}

/* Estilos para pantallas grandes */
@media (min-width: 769px) {
  .links-container {
    flex-direction: row;
    justify-content: center;
  }

  .link-item {
    margin-right: 20px;
  }

  .linkFooter {
    font-size: 18px;
  }
}
/* 
es la parte de atras de la casa, divide un patio grande, en la parte derecha son 3 habitaciones una l lado de la otra, 2 en suit
tiene 2 puertas cada hbaitacion se q comunican entre si y otra q da al living coeodr a lo largo de las 3 habitaciones, cocina al final q da un patio lindo con pileta */




/* FIN DE FOOTER */


.Contact-container {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f9f9f9;
  margin-top: 50px; 
  padding-top: 20px;
}



.Photogallery {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}






.mb-4 {
  margin-bottom:0px !important;
}



.rounded-button2 {
  padding: 10px 20px; 
  border-radius: 30px; 
  background-color: #23c4f4; 
  color: white; 
  border: none; 
  cursor: pointer; 
  font-size: 29px; 
}

.rounded-button2:hover {
  background-color: #e93291; 
  color: white;
}


.Collage {
  padding-top:130px;
  padding-bottom:100px;
  background-image: url('../public/img/background-photogallery.jpeg');
  background-color: rgba(255, 255, 255, 0.16);
  background-blend-mode: overlay;
  background-size: cover; 
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 70vh;
}

@media (max-width: 480px) {
  .Collage {
    padding-top:130px;
    padding-bottom:100px;
    background-image: url('../public/img/background-photogallery.jpeg');
    background-color: rgba(255, 255, 255, 0.1);
    background-blend-mode: overlay;
    background-size: cover; 
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 60vh;
    /* width: 50%; */
  }
}


@media (max-width: 896px) {
  .Collage {
    padding-top:50px;
    height: 105vh;
  }}

@media (max-width: 667px) {
  .Collage {
    padding-top:50px;
    height: 105vh;
  }}


  @media (max-width: 414px) {
    .Collage {
      padding-top:50px;
      height: 35vh;
    }}

@media (max-width: 375px) {
  .Collage {
    padding-top:50px;
    height: 45vh;
  }}



.Collage-home {
  max-width: 350px;
  height: 300px;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  
}


.Collage-home p{
  font-family: 'Quicksand-Medium', sans-serif;
  /* font-weight: 600; */
}

.PhotoGallery-title{
  font-family: 'Quicksand-Medium-Bold', sans-serif;
    font-weight: 600;
}

@media (max-width: 480px) {
  .Collage-home {
    max-width: 330px;
    height: 250px;
  }}






/* .Collage {
  padding-top: 130px;
  padding-bottom: 100px;
  background-image: url('../public/img/background-photogallery.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 70vh;
  position: relative; 

  
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.05); 
  }
} */


.photogallery-image {
  transition: opacity 0.3s ease;
}

.photogallery-image:hover {
  opacity: 0.8; 
}


/* MODAL */

.btn-modal {
  background-color: #3a3a3a !important;
  color: white !important;
  border: none !important;
  padding: 10px 20px !important;
  cursor: pointer !important;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.ReactModal__Content, .ReactModal__Content--after-open {
  padding-bottom: 80px;
}


/* LOCATION */

.img-location {
  max-width: 250 !important;
}


.title-location {
  font-family: Helvetica, sans-serif;
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 0px;
  text-align: center;
  background: #23c4f4!important;
  width: 100%;
  margin-bottom: 15px;
  color:white !important;
}

.table>:not(caption)>*>* {
  background: none;
}

div.table {
  border-color: rgb(35, 196, 244);
}

.table {
  align-items: center;
  text-align: center;
  max-width: 600px;
  background-color: white;
  padding-bottom: 20px;
  padding-top: 10px;
  border: 3px solid;
  border-color:rgb(35, 196, 244);
  padding-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
}

.table-Opendata {
  margin-left: auto;
  margin-right: auto;
  max-width: 350px;
}


.img-location {
  max-width: 250px;
}


.address {
  max-width: 250px;
  font-family: 'Quicksand-Medium', sans-serif;
  font-weight: 600;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
  margin-top: 20px;
}



.location-container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 140px;
  padding-bottom: 140px;
  position: relative;

  display: flex; 
  flex-direction: column; 
  align-items: center; 
  justify-content: center; 

  padding-left: 10px;
  padding-right: 10px;
}

.location  {
  background-image: url('../public/img/background-stores.jpg');
  background-size: cover; 
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%; 
}



.titleLocation {
  font-family: 'Quicksand-Medium-Bold', sans-serif;
  font-weight: 600;
  text-align: center;
  /* margin-left: 17px; */
  margin-bottom: 0px;
  margin-top: 15px;
}


.tbody {
  border-style:none;
  text-align: left;
}

.tbody td {
  padding-right: 5px; 
}


/* MAP */

.custom-icon {
  filter: 
    drop-shadow(0 0 10px rgba(255, 255, 255, 0.8))
    drop-shadow(0 0 20px rgba(237, 225, 56, 0.9))
    drop-shadow(0 0 20px rgba(255, 255, 255, 0.9));
}



/* Asegura que el contenedor del mapa tenga un tamaño adecuado y no se desborde */
.map-container {
  height: 400px;
  width: 100%;
  max-width: 100%;
  position: relative;
  overflow: hidden;
}

/* sizing od map container */

.leaflet-container {
  height: 28vh;
  width: 95%;
  margin-left: 5px;
}

.table-left {
  float: left;
  width: 50%; 
}

.table-right {
  float: right;
  width: 50%; 
}



@media (max-width: 768px) { 
  .table-content {
    flex-direction: column; 
  }
  
  .table-left,
  .table-right {
    width: 100%; 
    float: none; 
  }
  
  .leaflet-container {
    height: 25vh; 
    width: 90%; 
    margin-right: auto;
    margin-left: auto;
    margin-top:10px;
  }
}

/* .col-md-6 {
  width: 50%;768
} */

@media (max-width: 991px) {
  #photoSize  {
    width: 70%;
    margin-left:auto;
    margin-right:auto;
  }
} 

#popup-img {
  max-width: auto;
  max-height: auto;
  /* width: auto;
  height: auto; */
  margin: 0 auto;
}

.control-dots {
display: none;
}

#modalContent {
  max-width: 800px;
  margin: auto;
  max-height: 600px;
}

@media (max-width: 400px) {
  #modalContent  {

    max-height: 300px;
  }
} 

/* .carousel .control-dots {

  margin: 0px 0;

} */

.carousel-control-next-icon, .carousel-control-prev-icon {
  background-color: blue;
}






.carousel .control-arrow {
  color: #808080 !important; /* Asegúrate que el color gris se aplique */
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #808080 !important; /* Gris permanente */
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #808080 !important; /* Gris permanente */
}



.locationKS {
  font-family: "Verdana", sans-serif;
    font-size: 24px;       /* Tamaño de la fuente */
    font-weight: bold;     /* Grosor de la fuente */
    color: #333;           /* Color del texto */
    letter-spacing: 0.5px; /* Espaciado entre letras */
    line-height: 1.5;    
}



.custom-line {
  border: none;          /* Elimina el borde predeterminado */
  height: 2px;           /* Ajusta el grosor de la línea */
  background-color: #333; /* Color de la línea */
  width: 100%;           /* Ancho de la línea */
  margin: 20px 0;        /* Espaciado alrededor de la línea */
}



.address2 {
  font-size: 13px;
  margin-bottom: 0px;
  margin-top: 5px;
}




/* fixed horizontal phone mode */





/* btn carousel images */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
}


.modal-dialog {
  max-width: 900px;
}

.close {
  background-color: #00BFFF;
  color: white;
  border: 1px solid #00BFFF;
  /* border: none; */
  padding: 8px 20px;
  cursor: pointer;
  border-radius: 10px;
  position: absolute;
  top: 10;
  transition: background-color 0.3s ease;
  font-family: 'Arial', sans-serif; 
  font-weight: 600;
  margin-top: 10px;
}


.close:hover {
  background-color: #009ACD;
  border-color: #009ACD;
}

.modal-footer {
  border-top: none;
}


.gallery-button {
  margin-top: 30px;
  width: 150px;
  padding: 5=8px;
  background-color: #00BFFF;
  color: white;
  border: none;
  border-radius: 10px; 
  font-family: 'Arial', sans-serif; 
  font-weight: 600;
  font-size: 25px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease; 
}

.gallery-button:hover {
  background-color: #009ACD; 
}




/* INTRO */

.intro {
  max-width: 1200px;
  height: auto;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
margin-bottom: 35px;
margin-top: 50px;
padding-left: 15x;
padding-right: 15x;
}